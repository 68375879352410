import { ReactQueryDevtools } from "react-query/devtools";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { QueryClient, QueryClientProvider } from "react-query";
import AuthContextProvider from "common/contexts/AuthContext";
import React, { Suspense, lazy } from "react";
import ErrorBoundary from "common/components/ErrorBoundary";
import Login from "./Login";
import ImageViewer from "./common/ImageViewer";
import UserGroups from "./UserGroups";
import Refresh from "./Refresh";

const AppLayout = lazy(() => import("common/components/AppLayout"));
const Dashboard = lazy(() => import("./Dashboard"));
const User = lazy(() => import("./User"));
const Coupon = lazy(() => import("./Coupon"));
const Credential = lazy(() => import("./Setting"));
const Subscription = lazy(() => import("./Subscription"));
const Broadcasts = lazy(() => import("./Broadcasts"));
const InventoryManagement = lazy(() => import("./InventoryManagement"));
const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: false,
      refetchOnWindowFocus: false
    }
  }
});

function App() {
  return (
    <AuthContextProvider>
      <QueryClientProvider client={queryClient}>
        <BrowserRouter>
          <ErrorBoundary>
            <Suspense fallback={<div>Loading your content...</div>}>
              <Routes>
                <Route path="/" element={<AppLayout />}>
                  <Route index element={<Dashboard />} />
                  <Route path="dashboard" element={<Dashboard />} />
                  <Route path="user/*" element={<User />} />
                  <Route path="coupon/*" element={<Coupon />} />
                  <Route path="setting/*" element={<Credential />} />
                  <Route path="refresh/*" element={<Refresh />} />
                  <Route path="groups/*" element={<UserGroups />} />
                  <Route
                    path="subscription/:userId"
                    element={<Subscription />}
                  />
                  <Route
                    path="inventory-management/*"
                    element={<InventoryManagement />}
                  />
                  <Route path="/broadcasts" element={<Broadcasts />} />
                  <Route path="*" element={<Dashboard />} />
                </Route>
                <Route path="/login" element={<Login />} />
                <Route path="/image" element={<ImageViewer />} />
              </Routes>
            </Suspense>
          </ErrorBoundary>
        </BrowserRouter>
        <ReactQueryDevtools initialIsOpen={false} />
      </QueryClientProvider>
    </AuthContextProvider>
  );
}

export default App;
