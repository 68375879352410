const COLORS = {
  primary_green: "#00BFB8",
  primary_blue: "#f3fcff",
  primary_grey: "#d4d4d4",
  disabled_grey: "#EDEDED",
  sky: "rgba(0,191,184,1)",
  midnight_green: "#005754",
  pale_cyan: "#f3fdfc",
  wood_rush: "#3e3d3a",
  yellow: "#FFBB57",
  action_approve: "#00a400",
  action_decline: "#F93549",
  action_review: "#F9A435",
  pale_aqua: "#e6f5f4",
  white: "#fff",
  home_feature_card_gradient:
    "linear-gradient(180deg, #e6f5f4 0%, rgba(255, 255, 255, 0) 100%)",
  book_a_demo_button_gradient:
    "linear-gradient(315deg,#00bfb9 0%,rgba(0, 191, 185, 0.65) 100%)",
  dark_charcoal_grey: "#292929",
  bright_turquoise: "#00bfb9",
  muted_teal: "#2c817e80",
  black: "#000",
  dark_shaded_grey: "#292929",
  salmon_pink: "#FF91A4",
  dusty_grey: "#999999",
  login_body_gradient: "linear-gradient(135deg, #f4fcfc 0%, #e2f8f7 100%)",
  login_button_gradient:
    "linear-gradient(315deg,#00bfb9 0%,rgba(0, 191, 185, 0.65) 100%)",
  dark_teal: "#005754",
  primary_gradient:
    "linear-gradient(315deg, #00bfb9 0%, rgba(0, 191, 185, 0.65) 100%)",
  search_gradient: "linear-gradient(180deg, #fff 0%, #f5f5f5 100%)"
};

export default COLORS;
