import COLORS from "common/constants/Colors";

export const ROLE_CONFIG_KEYS = {
  user: "User",
  coupon: "Coupons",
  broadcasts: "Broadcasts",
  analytics: "Analytics",
  credential: "ERP_Credentials",
  inventory : "Inventory",
  preference: "Preferences",
  representative: "Representatives",
  group: "Groups",
  refresh: "Data_Refresh",
  notification: "Notifications",
  subscription: "Subscriptions"
};

export const DATE_FORMAT = {
  standard_dmy: "DD/MM/YYYY",
  hyphen_ymd: "YYYY-MM-DD"
};

export const PERMISSIONS = {
  read: "Read",
  create: "Create",
  select: "Select",
  delete: "Delete",
  update: "Update"
};

export const ROLE = {
  salesman: "ROLE_SALESMAN",
  distributor: "ROLE_DISTRIBUTOR",
  retailer: "ROLE_RETAILER"
};

export const REQUEST_STATUS = {
  SUBMITTED: "SUBMITTED",
  APPROVED: "APPROVED",
  DECLINED: "DECLINED",
  BLOCKED: "BLOCKED"
};

export const USER_MODE = {
  READ: "read",
  EDIT: "edit"
};

export const approvalActionsMappings = {
  APPROVED: { title: "Approve", color: COLORS.action_approve },
  DECLINED: { title: "Decline", color: COLORS.action_decline },
  REVIEW: { title: "Review", color: COLORS.action_review }
};
